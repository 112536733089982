.LF_KIT_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &.mobile {
    min-height: 64px !important;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: unset !important;
    padding-right: unset !important;
  }
  > .content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .text{
      flex-grow: 1;
    }
    &.mobile {
      padding-right: 0px !important;
      > .text > span {
        font-size: 13px !important;
      }
    }
    > .right {
      align-items: center;
      display: flex;

    }

  }
}

.LF_KIT_header_close_button{
  cursor: pointer;
  z-index: 1;
}
