.LF_KIT_Select-container{
    width: fit-content;
    height: auto;
    /*IE*/
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 100%;
    }
    border-radius: 4px;
    max-height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    &.mobile{
        position: unset;
    }
    .LF_KIT_Select-value{
        border: 1px solid #DAE4EE;
        box-sizing: border-box;
        border-radius: 4px;
        align-items: center;
        display: flex;

        justify-content: space-between;
        cursor: pointer;
        &.mobile{
            padding-left: 16px !important;
            padding-right: 24px !important;
            min-height: 48px !important;
            height: 48px !important;
            .LF_KIT_Select-iconOpen{
                margin-left: 20px !important;
            }
        }
    }

    &.open{
        box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.1);
        .LF_KIT_Select-value{
            border: none;
            border-bottom: 1px solid #DAE4EE;
            border-radius: unset;
        }
    }
    &.full_width{
        width: 100%;
    }
}




.LF_KIT_Select-container .LF_KIT_Select-list{
    width: 100%;
    height: 100%;
    z-index: 2;
    background: white;
    overflow: auto;
    max-height: 100%;
    &.mobile{
        padding-bottom: 8px !important;
        top: 0;
        //position: absolute;
    }
}

.LF_KIT_Select-container .LF_KIT_Select-list .LF_KIT_Select-item{
    &.mobile{
        height: 40px !important;
        padding-left: 16px !important;
        padding-right: 24px !important;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LF_KIT_Select-container .LF_KIT_Select-list .LF_KIT_Select-item:hover{
    background: #EBF0F4;
}

.LF_KIT_Select-container .LF_KIT_Select-list .LF_KIT_Select-item.active{
    background: #EBF0F4;
}


