/*@font-face {*/
    /*font-family: "Roboto";*/
    /*src: url("./fonts/Roboto-Light.ttf");*/
    /*font-style: normal;*/
    /*font-weight: 300;*/
/*}*/


/*@font-face {*/
    /*font-family: "Roboto";*/
    /*src: url("./fonts/Roboto-Medium.ttf");*/
    /*font-style: normal;*/
    /*font-weight: 400;*/
/*}*/

@font-face {
font-family: "RobotoImported";
src: url("./fonts/Roboto-Medium.ttf");
    font-style: normal;
    font-weight: 500;
}

/*@font-face {*/
    /*font-family: "Roboto";*/
    /*src: url("./fonts/Roboto-Bold.ttf");*/
    /*font-style: normal;*/
    /*font-weight: 700;*/
/*}*/


/*@font-face {*/
    /*font-family: "Roboto";*/
    /*src: url("./fonts/Roboto-Black.ttf");*/
    /*font-style: normal;*/
    /*font-weight: 800;*/
/*}*/


