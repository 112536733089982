.LF_KIT_Button{
    border-radius: 0px 0px 6px 0px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    &.mobile {
        height: 56px !important;
        border-radius: 4px;
        > span{
            font-size: 12px !important;
            font-weight: bold !important;
        }
    }
    &.disabled{
        cursor: not-allowed;
    }
    &.full_width{
        width: 100%;
    }
    &.space_between{
        justify-content: space-between;
    }

    &.with_caption{
        padding: 0 54px 0 40px;
        > .LF_KIT_Button-right_icon{
            margin-left: 22px;
        }
        > .LF_KIT_Button-left_icon{
            margin-right: 17px;
        }
    }

}


