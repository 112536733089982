.LF_KIT_Button_start_page {
  cursor: pointer;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px 0 10px;
    > .LF_KIT_Button-right_icon {
      margin-left: 22px;
    }
    &.mobile {
      height: 56px !important;
      border-radius: 4px;
      > span {
        font-size: 12px !important;
        font-weight: bold !important;
      }
    }
  }

}


