.LF_KIT_answers_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
  &.mobile {
    padding-left: 16px !important;
    padding-right: 16px;
    padding-top: 15px;
    height: unset;
  }

  > .tag {
    z-index: 1;

    &.mobile {
      margin-top: 10px !important;
    }
  }

  > .question {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    z-index: 3;
    flex-shrink: 0;

    &.mobile {
      margin-top: 0 !important;
      min-height: 45px !important;
      align-items: unset;
      padding-bottom: 20px;
    }

    &.mobile_big_height {
      min-height: 50px !important;
    }

    > .content {
      display: flex;
      z-index: 1;
      width: 100%;
      flex-direction: row;

      > .text {
        -webkit-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
        opacity: 0;
        width: 100%;
        justify-content: space-between;
        display: flex;

        //for ie
        > span {
          width: 100%;
        }

        &.in {
          opacity: 1;
        }

        .title_icon_container {
          display: flex;

          &.mobile {
            flex-direction: column !important;
            align-items: flex-start !important;
          }

          .title_icon {
            display: inline-block;
            cursor: pointer;
            position: relative;

            > svg {
              margin: auto;
              display: inline-block !important;
            }

            &.mobile {
              margin-left: 0 !important;
              margin-top: 22px;
              display: block;
              position: relative;
            }

            > .tip {
              display: none;

              &.show {
                display: block;
              }

              pointer-events: none;
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
              width: max-content;
              box-shadow: 0px 6px 36px rgba(0, 0, 0, 0.1);
              background: #FFF;

              &.mobile {
                border-radius: 4px !important;
                top: 135% !important;
                transform: none;
                left: -14px;
                margin-left: 5px;
              }

              .polygon {
                &.mobile {
                  bottom: unset;
                  top: 0px;
                  left: 5px;
                  transform: rotate(180deg);
                  margin: unset !important;
                }

                position: absolute;
                margin-left: auto;
                margin-right: auto;
                bottom: 0px;
                width: 32px;
                left: 0;
                right: 0;

                &::after {
                  position: absolute;
                  content: '';
                  border-left: 16px solid transparent;
                  border-right: 16px solid transparent;
                  border-top: 11px solid #FFF;
                }
              }

              .content {
                position: relative;
                z-index: 1;

                &.mobile {
                  padding-left: 30px !important;
                  padding-top: 32px !important;
                  padding-right: 28px !important;
                  padding-bottom: 37px !important;
                }

                .item {
                  display: flex;
                  align-items: center;

                  span {
                    padding-left: 10px;
                  }

                  &.mobile {
                    span {
                      font-size: 13px !important;
                      line-height: 16px !important;
                    }
                  }
                }
              }
            }

            &:hover {
              > .tip {
                display: block;
              }
            }
          }
        }
      }

      &.mobile {
        max-width: unset !important;
        position: relative;
        flex-direction: column;

        .text span {
          font-size: 18px !important;
          line-height: 24px !important;
        }
      }

    }

    > .control_arrows {
      &.mobile {
        display: none;
      }
    }

  }

  > .answer_container {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    z-index: 2;
    &.not_overflow_hidden{
      overflow: visible;
    }
    &.mobile_big_height {
      padding-top: 25px !important;
    }

    &.mobile {
      flex-grow: unset;
      margin-bottom: 0px !important;
      padding-top: 10px !important;
      margin-top: -10px;
      > .answer_content {
        padding: 0 !important;
        margin: 0 !important;
      }
      > .transparent_background_right{
        display: none;
      }
      //> .transparent_background_bottom{
      //  height: 30px !important;
      //  bottom: 0 !important;
      //}
    }

    &.inactive {
      //&::-webkit-scrollbar-thumb:vertical {
      //  background-color: #fff;
      //}
      //&::-webkit-scrollbar-thumb:horizontal {
      //  background-color: #fff;
      //}
      //scrollbar-color: #fff #fff;
      //scrollbar-color: #fff;
    }
    > .transparent_background_right{
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      background: linear-gradient(270deg, #FFFFFF 7.36%, rgba(255, 255, 255, 0) 100%);
    }
    //> .transparent_background_bottom{
    //  position: absolute;
    //  width: 100%;
    //  z-index: 9;
    //  background: linear-gradient(0deg, #FFFFFF 7.36%, rgba(255, 255, 255, 0) 100%);
    //}
    > .answer_content {
      position: relative;
      z-index: 1;
      flex-grow: 1;
      &.grid{
        display: grid;
        /*IE*/
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          //margin-right: -10px !important;
          //margin-bottom: -10px !important;
          display: -ms-grid;

          /* ie scrollbar color properties */
          scrollbar-face-color: #6f6f6f;
          scrollbar-arrow-color: #FFF;
          scrollbar-shadow-color: #6f6f6f;

          &:after {
            content: "";
            height: 20px;
            display: block;
          }
        }

        /*Edge*/
        @supports (-ms-ime-align:auto) {
          //margin-right: -10px !important;
          //margin-bottom: -10px !important;
          display: -ms-grid;

          scrollbar-face-color: #6f6f6f;
          scrollbar-arrow-color: #FFF;
          scrollbar-shadow-color: #6f6f6f;

          &:after {
            content: "";
            height: 10px;
            display: block;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #00000091;
        -webkit-border-radius: 5px;
      }

      &::-webkit-scrollbar-button:start:decrement,
      &::-webkit-scrollbar-button:end:increment {
        height: 5px;
        display: block;
      }

      scrollbar-color: #00000091 #fff;
      scrollbar-width: thin;


    }
  }

  .control_arrows_mobile {
    z-index: 1;
    position: absolute;
    //margin-top: calc(50% - 40px);
    //width: 100%;
    > div {
      justify-content: space-between;
      width: 100%;
    }
  }
}
