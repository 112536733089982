.LF_KIT_result_content_container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  &.mobile {
    padding-left: 16px !important;
    padding-right: 16px;
    padding-top: 15px;
    flex-direction: column;
    width: 100% !important;
    overflow: auto;
  }
    > .question {
      display: flex;
      align-items: flex-end;
      //flex: 1;
      z-index: 2;
      &.mobile{
        min-height: 45px !important;
        align-items: unset;
        margin-top: 0 !important;
        flex-direction: column;
        > .control_arrows{
          margin-left: 0px !important;
          margin-top: 20px;
        }
      }
      &.mobile_big_height {
        min-height: 50px !important;
      }
      > .content {
        display: flex;
        z-index: 1;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        > .text {
          -webkit-transition: all 0.7s ease-in-out;
          transition: all 0.7s ease-in-out;
          opacity: 0;
          &.in {
            opacity: 1;
          }
        }
        &.mobile{
          max-width: unset !important;
          position: relative;
          flex-direction: column;

          .text span {
            font-size: 18px !important;
            line-height: 24px !important;
          }
        }

      }

    }
    > .answer_container {
      z-index: 1;
      display: grid;
      overflow: hidden;
      position: relative;

      > .answer_content{
        overflow: auto;
        &.mobile {
          margin-bottom: 0px !important;
          padding-top: 20px !important;
          @media (min-height: 667px) {
            padding-top: 35px !important;
          }
        }
        position: relative;

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #00000091;
          -webkit-border-radius: 5px;
        }

        &::-webkit-scrollbar-button:start:decrement,
        &::-webkit-scrollbar-button:end:increment {
          height: 5px;
          display: block;
        }

        /*IE*/
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          margin-right: -10px !important;
          margin-bottom: -10px !important;
          display: -ms-grid;

          /* ie scrollbar color properties */
          scrollbar-face-color: #6f6f6f;
          scrollbar-arrow-color: #FFF;
          scrollbar-shadow-color: #6f6f6f;

          &:after {
            content: "";
            height: 20px;
            display: block;
          }
        }

        /*Edge*/
        @supports (-ms-ime-align:auto) {
          margin-right: -10px !important;
          margin-bottom: -10px !important;
          display: -ms-grid;

          scrollbar-face-color: #6f6f6f;
          scrollbar-arrow-color: #FFF;
          scrollbar-shadow-color: #6f6f6f;

          &:after {
            content: "";
            height: 10px;
            display: block;
          }
        }

        scrollbar-color: #00000091 #fff;
        scrollbar-width: thin;
      }

    }


}
