.LF_KIT_Date_time_picker{
    width: 100%;
    position: relative;
    .input_block{
        position: relative;
        .icon{
            position: absolute;
            right: 3px;
            bottom: 12px;
            cursor: pointer;
        }
    }
    .picker{
        position: absolute;
        left: calc(100% + 33px);
        top: -150px;
        &.mobile {
            position: unset;
            left: unset;
            top: unset;
        }
        &.unset_select_date{
            .react-datepicker__day--keyboard-selected{
                background-color: unset;
                color: unset;
                outline:none !important;
            }
        }
    }
}


