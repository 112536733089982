.LF_KIT_manager {
    display: flex;
    width: 100%;
    align-items: center;
    .desc{
        margin-left: 16px;
        height: fit-content;
        &.mobile {
            .job{
              span{
                    font-size: 12px !important;
                }
            }
            .name{
              span{
                    font-size: 14px !important;
                }
            }
        }
    }
}


.LF_KIT_manager_avatar {
   &.mobile {
        border-radius: 48px !important;
        width: 48px !important;
        height: 48px !important;
    }
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
