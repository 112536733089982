.LF_KIT_Additional_container {
  position: relative;
  &.mobile {
    width: unset !important;
    margin-left: unset !important;
    margin-right: unset !important;
    position: unset;
    z-index: 3;
  }

  .additional_block_open {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
    &.hide {
      visibility: hidden;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }

    position: relative;
    overflow: hidden;
    background: #F1F4F8;
    border-style: solid;
    border-color: #DAE4EE;
    border-radius: 5px;
    height: fit-content;
    &.second {
      padding: unset;
      border: none;
      background: none;
      border-radius: unset;
      overflow: unset;
      .top_row {
        padding-right: 0px !important;
        .hide_icon {
          position: unset;
        }
      }
    }
    .top_row {
      display: flex;
      justify-content: space-between;
      .hide_icon {
        position: absolute;
        cursor: pointer;
      }
    }
    &.mobile {
      display: none;
    }
  }
  .additional_block_close {
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:not(.mobile) {
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;

    }
    &.mobile {
      width: 100% !important;
      bottom: 70px;
      &.mobile_big_height{
        bottom: 112px;
      }
      left:0px;
      right: 0px;
      position: absolute;
      flex-direction: row;
      height: fit-content;
      padding-right: 18px;
      padding-left: 16px;
      padding-bottom: 15px;
      z-index: 1;
      background: #ffffff;
      margin: 0px;
      top: auto;
      @media (max-width: 767px) {
        position: fixed;
      }
    }

    &.mobile_big_height {
      padding-bottom: 25px;
    }
    .manager {
      flex-direction: column;
      align-items: center;
      &.mobile {
        flex-direction: row;
        padding-top: 0px !important;
        display: flex;
      }
      .avatar {
        cursor: pointer;
        .mobile {
          margin-bottom: 0px !important;
          margin-right: 20px;
        }
      }
      .text {
        margin-left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
        &.mobile {
          margin-top: unset !important;
          margin-left: unset !important;
          transform: unset !important;
          > span {
            font-size: 14px !important;
          }
        }
        cursor: pointer;
      }
    }
    .icons {
      display: flex;
      flex-direction: column;
      &.mobile{
        flex-direction: row;
        margin-bottom: 0px !important;
      }
      align-items: center;
      .percent {
        position: relative;
        .circle {
          background: #4CC724;
          position: absolute;
          width: 8px;
          height: 8px;
          top: -2px;
          border-radius: 4px;
          right: -2px;
          border: 2px solid #FFFFFF;
        }
        cursor: pointer;
      }
      .bonus {
        &.mobile {
          margin-top: 0px;
          margin-left: 21px;
        }
        margin-top: 24px;
        cursor: pointer;
      }
    }

  }
  .additional_block_open_mobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    opacity: 1;
    filter: alpha(opacity=100);
    @media (max-width: 767px) {
      position: fixed;
    }
    &.hide {
      visibility: hidden;
      opacity: 0;
      filter: alpha(opacity=0);
      .content {
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
      }
    }
    .content {
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transform: translateX(0);
      transform: translateY(0);
      -webkit-transition: all 0.7s ease-in-out;
      transition: all 0.7s ease-in-out;
      position: absolute;
      width: 100%;
      bottom: 0;
      @media (max-width: 767px) {
        position: fixed;
      }
      background: white;
      padding: 19px 16px 29px 16px;
      .top_row {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .icon {
          margin-top: 10px;
          z-index: 4;
        }

      }
    }
    .overlay {
      background: rgba(0, 0, 0, 0.64);
      width: 100%;
      height: 100%;
    }
  }

}
