.LF_KIT_header_start_page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.mobile{
    //display: none;
    //min-height: 94px !important;
  }
  > .content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .right {
      align-items: center;
      display: flex;
      > .phone{
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
      }
      > .close_button{
        cursor: pointer;
      }
    }
    > .left{
      display: flex;
      align-items: center;
      .logo{
        position: relative;
        img{
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
    &.mobile{
      flex-direction: column;
      align-items: flex-start;
      .left {
        min-height: 88px;
      }
      .logo{
        margin-right: 16px !important;
        > img {
          max-width: 150px !important;
          max-height: 56px !important;
        }
      }
      .text > span {
        font-size: 14px !important;
      }
      .legal > span {
        font-size: 13px !important;
      }
      .right {
        width: 100%;
        justify-content: center;
        min-height: 72px;
        .close_button{
          display: none;
        }
      }
      .phone > div > span {
        font-size: 16px !important;
      }
    }

  }
}
