.LF_KIT_answers_radio_container{
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    .other{
        &.mobile {
            //margin-bottom: 47px !important;
            margin-top: 20px !important;
        }
    }
    .wrapper{
        width: 100%;
        flex-flow: column wrap;
        display: flex;
        align-content: flex-start;
        position: relative;
        &.mobile {
            height: auto;
        }
    }
}

.LF_KIT_answers_checkbox_item {
    margin-right: 193px;
    &.mobile {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 24px !important;
    }
    .admin_container{
        margin-left: 35px;
        .delete_icon{
            cursor: pointer;
            margin-left: 35px;
            margin-top: -5px;
            position: absolute;
        }
        .launch_icon{
            cursor: pointer;
            margin-top: -5px;
            position: absolute;
        }
    }
}
