.LK-KIT-backgroundimage-container{
  width: 100%;
  .LK-KIT-backgroundimage{
    &.mobile {
      display: none !important;
    }
    #image1{
      display: none;
    }
    &.isAdmin{
      &:hover {
        cursor: pointer;
        #image1{
          display: unset;
        }
      }
    }

  }
  .LK-KIT-render_mobile{
    display: none;
    &.mobile {
      width: 100%;
      display: block !important;
    }
  }

  .close_button{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    &.mobile {
      display: block;
    }
  }
}
