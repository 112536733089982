.LF_KIT_answers_input_container{
  &.mobile {
    margin-top: 0px;
    > span {
      font-size: 14px !important;
    }
    .input{
      margin-top: 16px !important;
    }
  }

}
