.LF_KIT_Input{
    width: fit-content;
    &.mobile {
        padding-top: 7px !important;
        input{
            font-size: 18px !important;
            line-height: 38px !important;
            min-height: 40px !important;
        }
    }
    input{
        border: none !important;
        outline: 0;
        border-width: 0;
        background: none;
        font-family: 'Montserrat';
        color: #6B809F;
        font-weight: 500;
        width: 100%;
        caret-color: #000000;
        &::placeholder {
            color: #9FAFBD;
        }

//Remove Arrows/Spinners
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield;
        }

    }
    &.outlined{
        &.mobile {
            input{
                font-size: 16px !important;
                line-height: 44px !important;
                border-radius: 4px !important;
                min-height: 48px !important;
            }
        }
        input{
            font-weight: bold;
            border: 1px solid #DAE4EE !important;
            text-align: center;
        }
    }
    &.fullWidth{
        width: 100%;
    }
// для маски
    .react-tel-input .flag-dropdown{
        border: none !important;
    }
}


