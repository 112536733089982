.LF_KIT_Text {
  font-family: 'Montserrat';
  &.letter_spacing {
    letter-spacing: 0.15em;
  }
  &.bold {
    font-weight: bold;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.pointer {
    cursor: pointer;
  }
}

.LF_KIT_Admin_text {
  position: relative;
  width: fit-content;
  .input {
    top: 0;
    position: absolute;
    border: none;
    background: none;
    padding: 0;
    outline: 0;
    caret-color: #4CC724;
    color: inherit;
    width: 100%;
    resize: none;
    display: block;
    overflow: hidden;
    letter-spacing: inherit;
  }
  .tooltip {
    position: absolute;
    top: 0;
    right: -20px;
  }
  .icon {
    cursor: pointer;
    fill: #4CC724;
    width: 12px;
    margin-left: 5px;
    &.white{
      fill: white;
    }
  }
}





