.LF_KIT_answers_slider_container{
  &.mobile {
    margin: 0px;
    max-width: 100% !important;
  }
  .inputs{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
}
