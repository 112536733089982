.LF_KIT_tip_text_second {
    position: relative;
    &.mobile {
        margin-bottom: 8px !important;
        padding: 16px 28px 16px 16px !important;
    }
    border-radius: 4px;
    background: #F0F4F8;
    > .text{
        z-index: 1;
        position: relative;
        &.mobile {
            > span {
                font-size: 13px !important;
                line-height: 16px !important;
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: -12px;
        border-radius: 2px;
        border: 24px solid transparent; border-bottom: 24px solid #F0F4F8;
        border-left: 24px solid #F0F4F8;
    }
}
