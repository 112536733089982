@import "fonts.css";

.bottom-center{
    animation: bottom-center 0.5s  ease-in-out;
    -webkit-animation: bottom-center 0.5s  ease-in-out;
}
.center-top{
    animation: center-top 0.5s  ease-in-out;
    -webkit-animation: center-top 0.5s  ease-in-out;
    opacity: 0;
}
.center-bottom{
    animation: center-bottom 0.5s  ease-in-out;
    -webkit-animation: center-bottom 0.5s  ease-in-out;
    opacity: 0;
}
.top-center{
    animation: top-center 0.5s  ease-in-out;
    -webkit-animation: top-center 0.5s  ease-in-out;
}


@keyframes bottom-center {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes center-top {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
        visibility: hidden;
    }
}

@keyframes center-bottom {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
        visibility: hidden;
    }
}

@keyframes top-center {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes bottom-center {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-webkit-keyframes center-top {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        visibility: hidden;
    }
}

@-webkit-keyframes center-bottom {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        visibility: hidden;
    }
}

@-webkit-keyframes top-center {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}
