.LF_KIT_start_page {
  box-shadow: 0px 10px 42px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  position: relative;
  &.mobile {
    height: 100% !important;
    justify-content: flex-start;
    overflow: hidden;
  }

  > .content {
    //height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    &.mobile {
      flex-direction: column-reverse;
      //position: absolute;
      width: 100%;
      //@media (max-width: 767px) {
      //  position: fixed;
      //  bottom: 0;
      //}
    }

    > .left {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      z-index: 1;

      &.mobile {
        width: 100% !important;
        margin: 0 !important;
        padding: 16px !important;
        flex-grow: 1;
        overflow: unset !important;
      }

      .made_in {

      }

      .left_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;

        > .title {
          span {
            word-wrap: break-word;
          }

          > .LF_KIT_Admin_text {
            max-width: 100% !important;
          }
        }

        > .subtitle {
          span {
            word-wrap: break-word;
          }
        }

        &.mobile {
          justify-content: space-around;

          > .title {
            margin-bottom: 16px !important;

            span {
              font-size: 18px !important;
              line-height: 24px !important;
            }
          }

          .subtitle {
            marginBottom: 16px !important;

            span {
              font-size: 14px !important;
              line-height: 16px !important;
            }
          }
        }

        > .button {
          width: fit-content;

          &.mobile {
            width: 100% !important;
          }
        }
      }
    }

    > .right {
      align-items: flex-end;
      display: flex;
      position: relative;
      overflow: hidden;
      flex-grow: 0;
      flex-shrink: 0;

      &.mobile {
        flex-grow: unset;
        margin-top: 0px !important;
        flex-basis: unset;
      }
    }
  }
}
