.LF_KIT_Layout_container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    //ie-9
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 100vh;
    }
    width: 100%;
    @media (max-width: 767px) {
       align-items: stretch;
    }
    >.content{
        width: 1248px;
        /*IE*/
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: 100%;
        }
        @media (max-width: 1295px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 0 !important;
        }
    }
}
