.LF_KIT_finish_page {
  box-shadow: 0px 10px 42px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.mobile {
    height: 100% !important;
    justify-content: unset !important;
  }

  > .header {
    width: 100%;
    z-index: 1;
  }

  > .content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    width: 100%;
    &.mobile {
      flex-grow: 1;
      justify-content: center;
      .finish_title {
        margin-top: 0px !important;
        span {
          font-size: 30px !important;
          line-height: 40px !important;
        }
      }
    }

    .finish_subtitle {
      text-align: center;
      width: 100%;

      > .LF_KIT_Admin_text {
        margin: auto;
      }

      &.mobile {
        padding-top: 32px !important;
        padding-left: unset !important;
        padding-right: unset !important;

        span {
          font-size: 16px !important;
          line-height: 24px !important;
        }
      }
    }
  }

  > .background {
    position: absolute;
    bottom: 0;

    &.mobile {
      width: 100%;
    }
  }
}
