.LF_KIT_sale_second {
    border: 1px solid #DAE4EE;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    &.with_background{
        border: none;
    }
    &.mobile {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 27px 16px  24px !important;
    }
    .saleForStep{
        display: flex;
        align-items: flex-end;
        &.mobile {
            margin-top: 4px;
            span {
                line-height: 24px !important;
            }
            .min span {
                font-size: 12px !important;
            }
            .middle span {
                font-size: 13px !important;
            }
            .big span {
                font-size: 16px !important;
            }
        }
    }
    > .sale_amount{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        > .text{
            display: flex;
            align-items: flex-end;
            &.mobile {
                span {
                    line-height: 32px !important;
                }
                .big span{
                    font-size: 30px !important;
                }
                .middle span{
                    font-size: 24px !important;
                }
            }

        }
    }
}
