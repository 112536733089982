.LF_KIT_Result_one_container {
  box-shadow: 0px 10px 42px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  &.mobile {
    flex-direction: column;
    height: 100% !important;
    > .left {
      width: 100% !important;
    }
    > .right {
      padding: 16px;
      width: 100% !important;
      .content {
        padding: 0 !important;
      }
    }
  }
  > .left {
    position: relative;
    background: #c0c0c0;
  }
  > .right {
    justify-content: space-between;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .content {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      > .sale_amount_container {
        > .sale_amount {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          > .text {
            display: flex;
            align-items: flex-end;
            &.mobile {
              span {
                line-height: 32px !important;
              }
              .big span {
                font-size: 30px !important;
              }
              .middle span {
                font-size: 24px !important;
              }
            }

          }
        }
        border-radius: 5px;
        display: flex;
        width: fit-content;
        justify-content: space-between;
        .LF_KIT_Admin_text .tooltip {
          right: -113px;
          svg {
            fill: #6b7f9f;
          }
        }
        &.mobile {
          flex-direction: column;
          align-items: flex-start;
          padding: 16px 27px 16px 24px !important;
        }
      }

      .add_admin {
        cursor: pointer;
        margin-top: 5px;
        span {
          color: #4CC724 !important;
        }
      }
    }
    .button_container {

    }

  }

}
