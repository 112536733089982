.LF_KIT_Radio-container {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.LF_KIT_Radio {
  border-style: solid;
  box-sizing: border-box;
  display: flex;
  border-radius: 50%;
  flex: none;
  &.mobile {
    width: 16px !important;
    height: 16px !important;
    border-width: 2px !important;
  }
  .inner-circle {
    border-radius: 50%;
    margin: auto;
    &.mobile  {
      width: 8px !important;
      height: 8px !important;
    }
  }
}

.LF_KIT_Radio-label {
  &.mobile  {
    margin-left: 16px !important;
    line-height: 16px !important;
    font-size: 14px !important;
    span {
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }
}
