.LF_KIT_Tag{
    background: #6B809F;
    width: fit-content;
    display: flex;
    align-items: center;
    &.mobile {
      border-radius: 3px !important;
      padding: 4px 8px !important;
      > span {
        font-size: 13px !important;
      }
    }
    >.circle{
        background: #ffffff;
        &.mobile {
          margin-left: 8px !important;
          width: 8px !important;
          height: 8px !important;
          border-radius: 4px !important;
        }

    }
}


