.LF_KIT_answers_select_container{
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  &.mobile {
    margin-top: 0 !important;
    height: 100% !important;
    position: unset;
    .select{
      margin-top: 16px !important;
      margin-bottom: 0 !important;
    }
  }
  .select{
    height: 100%;
    flex-grow: 1;
    //position: relative;
  }
}
