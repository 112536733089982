.LF_KIT_answers_date_container{
  &.mobile {
    margin-top: 0px;
    width: 100% !important;
    > span {
      font-size: 14px !important;
    }
  }
  .picker{
    &.mobile {
      margin-top: 16px !important;
    }
  }
}
