.LF_KIT_Bonus{
    &.mobile {
        max-width: unset !important;
    }
    .top_row{
        display: flex;
        position: relative;
        width: fit-content;
        .big{
            &.mobile {
                span {
                    font-size: 30px!important;
                    line-height: 40px !important;
                }
            }
        }
        .little{
            &.mobile {
                margin-left: 8px !important;
                span{
                    font-size: 14px!important;
                    line-height: 30px !important;
                }
            }
        }
        .circle{
            border-radius: 50%;
            position: absolute;
            &.first{
                background: #EF9E00;
            }
            &.second{
                background: #4CC724;
            }
            &.mobile {
                width: 6px !important;
                height: 6px !important;
                right: -7px !important;
                top: 7px !important;
            }
        }
    }
    .text{
        margin-top: 10px !important;
        &.mobile {
            span {
                font-size: 13px!important;
                line-height: 16px !important;
            }
        }

    }

}
