.LF_KIT_Contact_container {
    box-shadow: 0px 10px 42px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    &.mobile {
        height: 100% !important;
        justify-content: unset !important;
    }
    >.content{
        display: flex;
        position: relative;
        padding-right: 72px;
        &.mobile {
            padding-right: unset;
            flex-direction: column;
            height: auto !important;
            overflow: hidden;
        }
    }
    > .navigation_container{
        &.mobile {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            z-index: 1;
            background: #ffffff;
            @media (max-width: 767px) {
                position: fixed;
            }
        }
    }

}
