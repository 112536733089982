.LF_KIT_Checkbox-container{
    display: flex;
    cursor: pointer;
    align-items: center;
}
.LF_KIT_Checkbox{
    border-color: #9FAFBD;
    border-style: solid;
    box-sizing: border-box;
    &.mobile {
        width: 16px !important;
        height: 16px !important;
        border-width: 2px !important;
        border-radius: 3px !important;
    }
    display: flex;
    flex: none;

    &.checked{
        > svg {
            margin: auto;
        }
        border: none;
    }
}

.LF_KIT_Checkbox-checked > div{
    margin: auto;
    width: fit-content;
}
.LF_KIT_Checkbox-label{
    &.mobile {
        margin-left: 16px !important;
        line-height: 16px !important;
        font-size: 14px !important;
        span {
            font-size: 14px !important;
            line-height: 16px !important;
        }
    }
}
