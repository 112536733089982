.slider_rail {
  .line {
    background: #E8EFF4;
    position: absolute;
    width: 100%;
    height: 6px;
    border-radius: 10px;
    z-index: 2;
    &.second {
      z-index: 3;
      opacity: 24%;
    }
  }
}

.slider_handle {
  > .control_container {
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3px;
    z-index: 3;
    &.mobile {
      &.left {
        align-items: flex-start;
      }
      &.right {
        align-items: flex-end;
      }
    }
    > .circle {
      cursor: pointer;
      border-style: solid;
      &.mobile {
        width: 32px !important;
        height: 32px !important;
        border-width: 6px !important;
        border-radius: 32px !important;
      }
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      .vertical_line {
        &.mobile {
          height: 6px !important;
          width: 1px !important;
          margin: 1.5px !important;
        }
      }
    }
  }
}

.LF_KIT_Slider {
  width: 100%;
  &.mobile {
    padding: 13px 0px 45px 0px !important;
  }
  > .content {
    position: relative;
    width: 100%;
    &.mobile {
      > .stop_line {
        display: none;
      }
      > .stop_text {
        display: none;
      }
    }
    > .stop_line {
      width: 1px;
      height: 40px;
      background: #E8EFF4;
      position: absolute;
      top: 3px;
      z-index: 1;
      &.max {
        right: 0;
      }
      &.min {
        left: 0;
      }
    }
    > .stop_text {
      position: absolute;
    }
  }
}


