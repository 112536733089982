@keyframes skewing {
    0%   { transform: translate(0, -200px); }
    100% { transform: translate(0, 0); }
}

@keyframes translating {
    0%   { transform: translate(0, 0); }
    25%  { transform: translate(240px, 0); }
    50%  { transform: translate(240px, 140px); }
    75%  { transform: translate(0, 140px); }
    100% { transform: translate(0, 0); }
}

.LF_KIT_navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &.mobile {
        height: 70px !important;
        width: 100%;
        &.mobile_big_height{
            height: 112px !important;
        }
    }
    > .progress_container {
        position: absolute;
        top: 0;
        width: 100%;
        > .progress {
            min-width: 10%;
            position: absolute;
            top: 0;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
        }
    }
    > .question {
        > span{
            position: relative;
        }
        .questionNumber{
            position: absolute;
        }
        &.mobile {
            display: none;
        }
        &.mobile_big_height {
            display: flex !important;
            position: absolute;
            justify-content: center;
            width: 100%;
            top: 8px;
            > span {
                font-size: 13px !important;
                line-height: 24px !important;
            }
        }
        user-select: none;
        display: flex;
    }
    > .left_button {
        &.mobile {
            &.mobile_big_height{
                margin-top: 31px;
            }
            width: fit-content !important;

            > div{
                padding: 0px 16px 0 17px;
            }
            span {
                display: none;
            }
        }
    }
    > .right_button {
        z-index: 1;
        width: auto;
        &.mobile {
            &.mobile_big_height{
                margin-top: 31px;
            }
            min-width: 240px !important;
            margin-right: 16px;
            > div {
                justify-content: center !important;
            }
        }
    }
}

