.LF_KIT_result {
  flex-flow: row wrap;
  display: flex;
  width: max-content;
  justify-content: flex-start;
  min-width: 100%;
  position: relative;
  height: max-content;

}

.LF_KIT_result_item {
  text-decoration: none;
  &:last-of-type {
    margin-right: 0 !important;
  }
  display: flex;
  flex-direction: column;
  &.mobile {
    width: 100%;
    margin-right: 13px !important;
    &:last-of-type {
      margin-right: 0 !important;
    }
    margin-bottom: 24px;
    > .title{
      margin-top: 24px !important;
      span {
        font-size: 18px !important;
        line-height: 24px !important;
      }
    }
    > .description{
      margin-top: 8px !important;
      span {
        font-size: 13px !important;
        line-height: 16px !important;
      }
    }
  }

  > .image_container {
    position: relative;
    .image {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      &.empty_image{
        background: #c0c0c0;
      }
      &.mobile {
        border-radius: 6px !important;
        > .sale_amount{
          width: 70px !important;
          height: 70px !important;
          top: -26px !important;
          right: -18px !important;
          border-radius: 70px !important;
          padding-top: 33px !important;
          padding-left: 15px !important;
          > .text_sale {
            margin-top: -10px !important;
            span{
              font-size: 8px !important;
              line-height: 8px !important;
            }
          }

          > .text{
            .value span{
              font-size: 15px !important;
            }
            .percent span{
              font-size: 10px !important;
              line-height: 15px !important;
            }
          }
        }
      }
      > .sale_amount{
        z-index: 9;
        position: absolute;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: self-start;
        > .text{
          display: flex;
          align-items: flex-end;
          .value {
            .LF_KIT_Admin_text .tooltip {
              right: -25px;
              svg {
                fill: #FFFFFF;
              }
            }
          }
        }
      }
    }
    .delete_icon {
      top: 10px;
      color: #4A4A4A;
      left: 70px;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      background: white;
      border-radius: 6px;
    }
    .launch_icon {
      top: 10px;
      left: 40px;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      background: white;
      border-radius: 6px;
    }
    .settings_icon {
      top: 10px;
      left: 10px;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      background: white;
      border-radius: 6px;
      .branchesCount{
        border-radius: 11px;
        width: 11px;
        padding: 2px;
        position: absolute;
        height: 11px;
        right: 0;
        bottom: 0;
        font-size: 8px;
        display: inline-block;
        text-align: center;
        color: #FFFFFF;
        background-color: #4CC724;
      }
    }
    &.mobile {
      margin-top: 16px !important;
      border-radius: 10px !important;
    }

    &.checked {
      border-style: solid;
      border-width: 2px;
      &.mobile {
        padding: 4px !important;
      }
    }
    > img {
      display: block;
    }
  }
  .add_admin{
    cursor: pointer;
    margin-top: 5px;
    span {
      color: #4CC724 !important;
    }
  }
}

